import { bindActionCreators } from "redux";

import { storeCommonAction } from "@/store";
import { commonConfig } from "@/utils/config";
import { permissionConstants } from "@/utils/constants";

import { Collapse, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import IMediaTextSvg from "@@/public/images/svgs/i-media-text.svg";
import IMediaSvg from "@@/public/images/i-media.svg";
import VtlCompanyTextLogo from "@@/public/images/svgs/vtl-company-text-logo.svg";
import VtlCompanyLogo from "@@/public/images/svgs/vtl-company-logo.svg";
import UserIcon from "@@/public/images/icons/user.svg";
import UserRoleIcon from "@@/public/images/icons/user-role.svg";
import CenterIcon from "@@/public/images/icons/center.svg";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import StaffIcon from "@@/public/images/icons/staff.svg";
import CategoriesIcon from "@@/public/images/icons/categories.svg";
import SalonIcon from "@@/public/images/icons/salon.svg";
import GradeLevelIcon from "@@/public/images/icons/grade-level.svg";
import CustomerIcon from "@@/public/images/icons/customer.svg";
import PlatformIcon from "@@/public/images/icons/platform.svg";
import AppointmentIcon from "@@/public/images/icons/appointment.svg";
import AdminHistoryIcon from "@@/public/images/icons/admin-history.svg";
import EmailIcon from "@@/public/images/icons/email.svg";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PerfectScrollbar from "react-perfect-scrollbar";

import AppLink from "@/components/AppLink";
import AdminAppBarToolbar from "@/components/AdminAppBarToolbar";
import AppList from "@/components/AppList";
import AppListItem from "@/components/AppListItem";
import AppListItemText from "@/components/AppListItemText";
import AppChip from "@/components/AppChip";
import AppDrawer from "@/components/AppDrawer";
import AppListItemButton from "@/components/AppListItemButton";
import AppSvgIcon from "@/components/AppSvgIcon";

import { useRouter } from "next/router";
import {
  useAppDispatch,
  useAppSelector,
  usePermission,
  useIsMounted,
} from "@/hooks";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useState } from "react";

import useStyles from "./Sidebar.styles";

type MenuListProps = {
  menuLevel?: number;
  sidebarOpened?: boolean;
  sidebarCollapsed?: boolean;
  isPrimaryMenu?: boolean;
  menus: Partial<(typeof menus)[number]>[];
};

type Menu = {
  name: string;
  Icon: React.ElementType;
  path?: string;
  activePath: RegExp;
  permissionName?: string;
  subMenus?: Menu[];
};

const menus: Menu[] = [
  {
    name: "appointments",
    Icon: AppointmentIcon,
    path: "/appointments",
    activePath: new RegExp("^/appointments"),
    permissionName: permissionConstants.APPOINTMENT_VIEW_NAME,
  },
  {
    name: "dashboard",
    Icon: DashboardIcon,
    path: "/dashboard",
    activePath: new RegExp("^/dashboard"),
    permissionName: permissionConstants.REPORT_VIEW_NAME,
  },
  {
    name: "notifications",
    Icon: NotificationsOutlinedIcon,
    path: "/notifications",
    activePath: new RegExp("^/notifications"),
  },
  {
    name: "gradeLevels",
    Icon: GradeLevelIcon,
    path: "/grade-levels",
    activePath: new RegExp("^/grade-levels"),
    permissionName: permissionConstants.BEAUTY_CENTER_VIEW_NAME,
  },
  {
    name: "categories",
    Icon: CategoriesIcon,
    path: "/categories",
    activePath: new RegExp("^/categories"),
    permissionName: permissionConstants.CATEGORY_VIEW_NAME,
  },
  {
    name: "productServices",
    Icon: SalonIcon,
    path: "/product-services",
    activePath: new RegExp("^/product-services"),
    permissionName: permissionConstants.PRODUCT_SERVICE_VIEW_NAME,
  },
  {
    name: "beautyCenters",
    Icon: CenterIcon,
    path: "/beauty-centers",
    activePath: new RegExp("^/beauty-centers"),
    permissionName: permissionConstants.BEAUTY_CENTER_VIEW_NAME,
  },
  {
    name: "districts",
    Icon: LocationOnOutlinedIcon,
    path: "/districts",
    activePath: new RegExp("^/districts"),
    permissionName: permissionConstants.DISTRICT_VIEW_NAME,
  },
  {
    name: "staffs",
    Icon: StaffIcon,
    path: "/staffs",
    activePath: new RegExp("^/staffs"),
    permissionName: permissionConstants.STAFF_VIEW_NAME,
  },
  {
    name: "customers",
    Icon: CustomerIcon,
    path: "/customers",
    activePath: new RegExp("^/customers"),
    permissionName: permissionConstants.CUSTOMER_VIEW_NAME,
  },
  {
    name: "platformCodes",
    Icon: PlatformIcon,
    path: "/platform-codes",
    activePath: new RegExp("^/platform-codes"),
    permissionName: permissionConstants.PLATFORM_CODE_VIEW_NAME,
  },
  {
    name: "roles",
    Icon: UserRoleIcon,
    path: "/roles",
    activePath: new RegExp("^/roles"),
    permissionName: permissionConstants.ROLE_VIEW_NAME,
  },
  {
    name: "users",
    Icon: UserIcon,
    path: "/users",
    activePath: new RegExp("^/users"),
    permissionName: permissionConstants.ADMIN_USER_VIEW_NAME,
  },
  {
    name: "emails",
    Icon: EmailIcon,
    path: "/emails",
    activePath: new RegExp("^/emails"),
    permissionName: permissionConstants.EMAIL_VIEW_NAME,
  },
  {
    name: "adminActionLogs",
    Icon: AdminHistoryIcon,
    path: "/admin-action-logs",
    activePath: new RegExp("^/admin-action-logs"),
    permissionName: permissionConstants.ADMIN_ACTION_LOG_VIEW_NAME,
  },
  {
    name: "smsLogs",
    Icon: SmsOutlinedIcon,
    path: "/sms-logs",
    activePath: new RegExp("^/sms-logs"),
    permissionName: permissionConstants.SMS_LOG_VIEW_NAME,
  },
];

const MenuList = (props: MenuListProps) => {
  const {
    menuLevel = 0,
    menus,
    sidebarOpened,
    sidebarCollapsed,
    isPrimaryMenu = false,
  } = props;

  const router = useRouter();

  const { canAccess } = usePermission();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const $s_commonAction = useMemo(
    () => bindActionCreators(storeCommonAction, dispatch),
    [dispatch]
  );

  const [collapsedSubMenuMap, setCollapsedSubMenuMap] = useState<{
    [menuIndex: number]: boolean;
  }>(() => {
    const collapsedMenuIndex = menus.findIndex(
      (menu) =>
        router.pathname.match(menu?.activePath || "") &&
        (menu.subMenus?.length || 0) > 0
    );
    return collapsedMenuIndex > -1
      ? {
          [collapsedMenuIndex]: true,
        }
      : {};
  });

  const { classes, cx } = useStyles();

  const handleSubMenuToggle = (menuIndex: number) => () => {
    setCollapsedSubMenuMap((prevCollapsedSubMenuMap) => ({
      ...prevCollapsedSubMenuMap,
      [menuIndex]: !prevCollapsedSubMenuMap[menuIndex],
    }));
  };

  const handleRouteChange = (url: string) => {
    const collapsedMenuIndex = menus.findIndex(
      (menu) =>
        url.match(menu.activePath || "") && (menu.subMenus?.length || 0) > 0
    );
    setCollapsedSubMenuMap(
      collapsedMenuIndex > -1
        ? {
            [collapsedMenuIndex]: true,
          }
        : {}
    );
  };

  useEffect(() => {
    if (isMounted()) {
      handleRouteChange(router.asPath);
    }
  }, [router.asPath]);

  const isMounted = useIsMounted();

  return (
    <AppList disablePadding={!isPrimaryMenu}>
      {menus.map((menu, menuIndex) => {
        const MenuIcon = menu?.Icon;
        const hasSubMenu = (menu!.subMenus?.length || 0) > 0;

        return (
          (!menu.permissionName! || canAccess(menu.permissionName!)) && (
            <AppListItem
              className={cx(classes.listItem, {
                [classes.selected]: !!router.pathname.match(
                  menu?.activePath || ""
                ),
              })}
              key={menuIndex}
              disablePadding
            >
              <AppListItemButton
                sx={{
                  justifyContent: sidebarOpened ? "initial" : "center",
                  px: 2.5,
                  paddingLeft:
                    2.5 * 2 + 2.5 + 2.5 * (isPrimaryMenu ? 0 : menuLevel - 1),
                }}
                classes={{
                  root: cx(
                    classes.listItemButton,
                    !isPrimaryMenu && classes.listSubItemButton
                  ),
                }}
                component={menu?.path ? AppLink : "div"}
                href={menu?.path}
                onClick={
                  hasSubMenu
                    ? handleSubMenuToggle(menuIndex)
                    : () => {
                        $s_commonAction.setFloatAdminSidebarOpened(false);
                      }
                }
              >
                {!!MenuIcon && isPrimaryMenu ? (
                  <AppSvgIcon
                    component={MenuIcon}
                    className={classes.listItemIcon}
                  />
                ) : (
                  <FiberManualRecordIcon
                    className={classes.listItemIcon}
                    sx={(theme) => ({
                      fontSize: !!router.pathname.match(menu?.activePath || "")
                        ? 12
                        : 6,
                      left: `${theme.spacing(
                        2.5 + 1.25 + 2.5 * (isPrimaryMenu ? 0 : menuLevel - 1)
                      )} !important`,
                    })}
                  />
                )}
                <AppListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={t(menu!.name! as any)}
                  sx={{ display: sidebarCollapsed ? "none" : "initial" }}
                />
                {hasSubMenu && (
                  <KeyboardArrowLeftIcon
                    className={classes.listItemArrow}
                    color="inherit"
                    fontSize="small"
                    sx={{
                      transform: !!collapsedSubMenuMap[menuIndex]
                        ? "rotate(-90deg)"
                        : "rotate(0deg)",
                      display: sidebarCollapsed ? "none" : "initial",
                    }}
                  />
                )}
              </AppListItemButton>
              {hasSubMenu && (
                <Collapse
                  in={!!collapsedSubMenuMap[menuIndex] && !sidebarCollapsed}
                  timeout="auto"
                  unmountOnExit
                >
                  <MenuList
                    menus={menu!.subMenus!}
                    sidebarCollapsed={sidebarCollapsed}
                    sidebarOpened={sidebarOpened}
                    menuLevel={menuLevel + 1}
                  />
                </Collapse>
              )}
            </AppListItem>
          )
        );
      })}
    </AppList>
  );
};

const Sidebar = () => {
  const [rootHovered, setRootHovered] = useState(false);

  const dispatch = useAppDispatch();

  const $s_commonAction = useMemo(
    () => bindActionCreators(storeCommonAction, dispatch),
    [dispatch]
  );

  const $s_adminSidebarCollapseOpened = useAppSelector(
    (state) => state.common.adminSidebarCollapseOpened
  );
  const $s_floatAdminSidebarOpened = useAppSelector(
    (state) => state.common.floatAdminSidebarOpened
  );

  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const isCollapsedOnHover = useMemo(
    () => $s_adminSidebarCollapseOpened && !isMdDown && !rootHovered,
    [$s_adminSidebarCollapseOpened, isMdDown, rootHovered]
  );

  const handleSidebarClose = () => {
    $s_commonAction.setFloatAdminSidebarOpened(!$s_floatAdminSidebarOpened);
  };

  return (
    <AppDrawer
      className={cx(classes.drawer, {
        [classes.drawerCollapseOnHover]: isCollapsedOnHover,
        [classes.drawerCollapsed]: $s_adminSidebarCollapseOpened,
      })}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      variant={isMdDown ? "temporary" : "permanent"}
      open={$s_floatAdminSidebarOpened}
      onClose={handleSidebarClose}
      onMouseEnter={() => setRootHovered(true)}
      onMouseLeave={() => setRootHovered(false)}
    >
      <div className={classes.drawerContent}>
        <AdminAppBarToolbar className={classes.logo}>
          {isCollapsedOnHover ? (
            <IMediaTextSvg className={classes.logoCollapseImage} />
          ) : (
            <>
              <IMediaSvg className={classes.logoImage} />
              <AppChip
                size="small"
                borderRadius="rounded"
                color="info.main"
                label={`v.${commonConfig.APP_VERSION}`}
                sx={{
                  display: isCollapsedOnHover ? "none" : undefined,
                }}
              />
            </>
          )}
        </AdminAppBarToolbar>
        <PerfectScrollbar className={classes.listContainer}>
          <MenuList
            menus={menus}
            sidebarCollapsed={isCollapsedOnHover}
            sidebarOpened={$s_floatAdminSidebarOpened}
            isPrimaryMenu
          />
        </PerfectScrollbar>
        <AppList disablePadding>
          <AppListItem className={classes.companyLogo}>
            {isCollapsedOnHover ? (
              <VtlCompanyLogo className={classes.companyLogoSvg} />
            ) : (
              <VtlCompanyTextLogo className={classes.companyTextLogoSvg} />
            )}
          </AppListItem>
        </AppList>
      </div>
    </AppDrawer>
  );
};

export default Sidebar;
