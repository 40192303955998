import { DistrictActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchDistrictSucceededServerAction,
  // Saga
  FetchOptionDistrictsSagaAction,
  FetchDistrict1sSagaAction,
  FetchDistrict2sSagaAction,
  FetchDistrict3sSagaAction,
  DeleteDistrictSagaAction,
  CreateDistrictSagaAction,
  UpdateDistrictSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: DistrictActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: DistrictActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: DistrictActionTypes.FETCH_FAILED,
  payload,
});

export const fetchDistrictSucceededServer = (
  payload: FetchDistrictSucceededServerAction["payload"]
): FetchDistrictSucceededServerAction => ({
  type: DistrictActionTypes.FETCH_DISTRICT_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchOptionDistrictsSaga = (
  payload?: FetchOptionDistrictsSagaAction["payload"],
  meta?: FetchOptionDistrictsSagaAction["meta"]
): FetchOptionDistrictsSagaAction => ({
  type: DistrictActionTypes.FETCH_OPTION_DISTRICTS_SAGA,
  payload,
  meta,
});

export const fetchDistrict1sSaga = (
  payload?: FetchDistrict1sSagaAction["payload"],
  meta?: FetchDistrict1sSagaAction["meta"]
): FetchDistrict1sSagaAction => ({
  type: DistrictActionTypes.FETCH_DISTRICT1S_SAGA,
  payload,
  meta,
});

export const fetchDistrict2sSaga = (
  payload?: FetchDistrict2sSagaAction["payload"],
  meta?: FetchDistrict2sSagaAction["meta"]
): FetchDistrict2sSagaAction => ({
  type: DistrictActionTypes.FETCH_DISTRICT2S_SAGA,
  payload,
  meta,
});

export const fetchDistrict3sSaga = (
  payload?: FetchDistrict3sSagaAction["payload"],
  meta?: FetchDistrict3sSagaAction["meta"]
): FetchDistrict3sSagaAction => ({
  type: DistrictActionTypes.FETCH_DISTRICT3S_SAGA,
  payload,
  meta,
});

export const deleteDistrictSaga = (
  payload: DeleteDistrictSagaAction["payload"],
  meta?: DeleteDistrictSagaAction["meta"]
): DeleteDistrictSagaAction => ({
  type: DistrictActionTypes.DELETE_DISTRICT_SAGA,
  payload,
  meta,
});

export const createDistrictSaga = (
  payload: CreateDistrictSagaAction["payload"],
  meta?: CreateDistrictSagaAction["meta"]
): CreateDistrictSagaAction => ({
  type: DistrictActionTypes.CREATE_DISTRICT_SAGA,
  payload,
  meta,
});

export const updateDistrictSaga = (
  payload: UpdateDistrictSagaAction["payload"],
  meta?: UpdateDistrictSagaAction["meta"]
): UpdateDistrictSagaAction => ({
  type: DistrictActionTypes.UPDATE_DISTRICT_SAGA,
  payload,
  meta,
});
