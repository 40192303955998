import type {
  CreateDistrictPayload,
  DeleteDistrictPayload,
  FetchDistrictResponseData,
  FetchDistrictsPayload,
  FetchDistrictsResponseData,
  FetchOptionDistrictsPayload,
  FetchOptionDistrictsResponseData,
  UpdateDistrictPayload,
} from "@/utils/apis/district";

export enum DistrictActionTypes {
  FETCH_REQUESTED = "@@district/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@district/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@district/FETCH_FAILED",

  FETCH_DISTRICT_SUCCEEDED_SERVER = "@@gradeLevel/FETCH_DISTRICT_SUCCEEDED_SERVER",

  // Saga
  FETCH_OPTION_DISTRICTS_SAGA = "@@district/FETCH_OPTION_DISTRICTS_SAGA",

  FETCH_DISTRICT1S_SAGA = "@@gradeLevel/FETCH_DISTRICT1S_SAGA",
  FETCH_DISTRICT2S_SAGA = "@@gradeLevel/FETCH_DISTRICT2S_SAGA",
  FETCH_DISTRICT3S_SAGA = "@@gradeLevel/FETCH_DISTRICT3S_SAGA",
  UPDATE_DISTRICT_SAGA = "@@gradeLevel/UPDATE_DISTRICT_SAGA",
  CREATE_DISTRICT_SAGA = "@@gradeLevel/CREATE_DISTRICT_SAGA",
  DELETE_DISTRICT_SAGA = "@@gradeLevel/DELETE_DISTRICT_SAGA",
}

// State

export type DistrictTree = FetchOptionDistrictsResponseData["data"][number] & {
  is_last_item: boolean;
  sub_districts: DistrictTree[];
  sub_district_ids: number[];
};

export interface DistrictState {
  hydrated?: boolean;

  optionDistricts: FetchOptionDistrictsResponseData["data"];
  optionDistrictsLoading: boolean;
  optionDistrictsError: string;

  district1s: FetchDistrictsResponseData["data"];
  district1sLoading: boolean;
  district1sError: string;
  district1sCount: number;

  district2s: FetchDistrictsResponseData["data"];
  district2sLoading: boolean;
  district2sError: string;
  district2sCount: number;

  district3s: FetchDistrictsResponseData["data"];
  district3sLoading: boolean;
  district3sError: string;
  district3sCount: number;

  district: FetchDistrictResponseData["data"] | null;
  districtLoading: boolean;
  districtError: string;
}

// ---- Reducer Action ----

export type FetchScope =
  | "optionDistricts"
  | "district1s"
  | "district2s"
  | "district3s"
  | "district";

export type FetchRequestedAction = {
  type: DistrictActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: DistrictActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: DistrictState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: DistrictActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchDistrictSucceededServerAction = {
  type: DistrictActionTypes.FETCH_DISTRICT_SUCCEEDED_SERVER;
  payload: FetchDistrictResponseData["data"] | null;
};

// ---- Saga Action ----

export type FetchOptionDistrictsSagaAction = {
  type: DistrictActionTypes.FETCH_OPTION_DISTRICTS_SAGA;
  payload?: FetchOptionDistrictsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchDistrict1sSagaAction = {
  type: DistrictActionTypes.FETCH_DISTRICT1S_SAGA;
  payload?: FetchDistrictsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchDistrict2sSagaAction = {
  type: DistrictActionTypes.FETCH_DISTRICT2S_SAGA;
  payload?: FetchDistrictsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchDistrict3sSagaAction = {
  type: DistrictActionTypes.FETCH_DISTRICT3S_SAGA;
  payload?: FetchDistrictsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type CreateDistrictSagaAction = {
  type: DistrictActionTypes.CREATE_DISTRICT_SAGA;
  payload: CreateDistrictPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateDistrictSagaAction = {
  type: DistrictActionTypes.UPDATE_DISTRICT_SAGA;
  payload: UpdateDistrictPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteDistrictSagaAction = {
  type: DistrictActionTypes.DELETE_DISTRICT_SAGA;
  payload: DeleteDistrictPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DistrictAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchDistrictSucceededServerAction
  //
  | FetchOptionDistrictsSagaAction
  | FetchDistrict1sSagaAction
  | FetchDistrict2sSagaAction
  | FetchDistrict3sSagaAction
  | DeleteDistrictSagaAction
  | CreateDistrictSagaAction
  | UpdateDistrictSagaAction;
