import { DistrictActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";

import type { DistrictState, DistrictAction } from "./types";
import { HYDRATE } from "next-redux-wrapper";

export const initialState: DistrictState = {
  optionDistricts: [],
  optionDistrictsError: "",
  optionDistrictsLoading: true,

  district: null,
  districtError: "",
  districtLoading: true,

  district1s: [],
  district1sError: "",
  district1sLoading: true,
  district1sCount: 0,

  district2s: [],
  district2sError: "",
  district2sLoading: true,
  district2sCount: 0,

  district3s: [],
  district3sError: "",
  district3sLoading: true,
  district3sCount: 0,
};

const reducer = (
  state = initialState,
  action: DistrictAction
): DistrictState => {
  switch (action.type) {
    case HYDRATE as any: {
      const { district, districtError, districtLoading, hydrated } = (
        action as any
      ).payload.district as DistrictState;

      const newState = {
        district,
        districtError,
        districtLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case DistrictActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case DistrictActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...filteredData];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case DistrictActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case DistrictActionTypes.FETCH_DISTRICT_SUCCEEDED_SERVER: {
      return {
        district: action.payload,
        districtLoading: false,
        districtError: "",
        hydrated: true,
      } as Partial<DistrictState> as DistrictState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
