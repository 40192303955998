import defaultTheme from "@/theme";

export const RECONFIRM_TYPE = "reconfirm";
export const BOOKING_TYPE = "booking";

export const COMPLETED_STATUS = "completed";
export const SENDING_STATUS = "wait_for_sending";
export const FAILED_STATUS = "failed";
export const NO_SEND_STATUS = "no_send";

export const typeItems = [
  {
    label: "appointment",
    type: BOOKING_TYPE,
    color: defaultTheme.palette.info.main,
  },
  {
    label: "reconfirm",
    type: RECONFIRM_TYPE,
    color: defaultTheme.palette.success.main,
  },
];

export const typeToItemMap = typeItems.reduce((item, typeItem) => {
  item[typeItem.type] = typeItem;
  return item;
}, {} as Record<string, (typeof typeItems)[number]>);

export const statusItems = [
  {
    label: "sending",
    status: SENDING_STATUS,
    color: defaultTheme.palette.info.main,
  },
  {
    label: "sendFailed",
    status: FAILED_STATUS,
    color: defaultTheme.palette.error.main,
  },
  {
    label: "noSend",
    status: NO_SEND_STATUS,
    color: defaultTheme.palette.text.secondary,
  },
  {
    label: "completed",
    status: COMPLETED_STATUS,
    color: defaultTheme.palette.success.main,
  },
];

export const statusToItemMap = statusItems.reduce((item, statusItem) => {
  item[statusItem.status] = statusItem;
  return item;
}, {} as Record<string, (typeof statusItems)[number]>);
