import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { appointmentApi } from "@/utils/apis";

import { AppointmentActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchScope,
  FetchDraftAppointmentsSagaAction,
  UpdateDraftAppointmentSagaAction,
  CreateDraftAppointmentSagaAction,
  SavePublishedAppointmentSagaAction,
  CancelAppointmentSagaAction,
  FetchProcessingAssignAppointmentsSagaAction,
  FetchInCompletedAppointmentsSagaAction,
  FetchCancelledAppointmentsSagaAction,
  FetchAppointmentSagaAction,
  AssignManualAppointmentSagaAction,
  FetchRescheduledAppointmentsSagaAction,
  FetchAcceptedAppointmentsSagaAction,
  AssignRescheduledBeautyCenterAppointmentSagaAction,
  AssignRescheduledCustomerAppointmentSagaAction,
  FetchAppointmentsSagaAction,
  UpdateAppointmentSagaAction,
} from "./types";
import { storeAppointmentStatusSelectors } from "@/store";
import { appointmentStatusConstants } from "@/utils/constants";

function* fetchAppointmentSaga(action: FetchAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "appointment" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointment>> = yield call(
      appointmentApi.fetchAppointment,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data ?? null,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchAppointmentsSaga(action: FetchAppointmentsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "appointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchDraftAppointmentsSaga(action: FetchDraftAppointmentsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "draftAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const draftGroupStatuses = statusGroup[
    appointmentStatusConstants.StatusGroupCode.DRAFT
  ]!.map((status) => status.code);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...draftGroupStatuses],
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchProcessingAssignAppointmentsSaga(
  action: FetchProcessingAssignAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "processingAssignAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const draftGroupStatuses = statusGroup[
    appointmentStatusConstants.StatusGroupCode.PROCESSING_ASSIGN
  ]!.map((status) => status.code);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...draftGroupStatuses],
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchRescheduledAppointmentsSaga(
  action: FetchRescheduledAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "rescheduledAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const draftGroupStatuses = statusGroup[
    appointmentStatusConstants.StatusGroupCode.RESCHEDULED
  ]!.map((status) => status.code);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...draftGroupStatuses],
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchAcceptedAppointmentsSaga(
  action: FetchAcceptedAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "acceptedAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const draftGroupStatuses = statusGroup[
    appointmentStatusConstants.StatusGroupCode.ACCEPTED
  ]!.map((status) => status.code);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...draftGroupStatuses],
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchCheckedInAppointmentsSaga(
  action: FetchAcceptedAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "checkedInAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const draftGroupStatuses = statusGroup[
    appointmentStatusConstants.StatusGroupCode.CHECKED_IN
  ]!.map((status) => status.code);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...draftGroupStatuses],
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchAbsenceAppointmentsSaga(
  action: FetchAcceptedAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "absenceAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const draftGroupStatuses = statusGroup[
    appointmentStatusConstants.StatusGroupCode.ABSENCE
  ]!.map((status) => status.code);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...draftGroupStatuses],
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchInCompletedAppointmentsSaga(
  action: FetchInCompletedAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "inCompletedAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const draftGroupStatuses = statusGroup[
    appointmentStatusConstants.StatusGroupCode.IN_COMPLETED
  ]!.map((status) => status.code);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...draftGroupStatuses],
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchCancelledAppointmentsSaga(
  action: FetchCancelledAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "cancelledAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const draftGroupStatuses = statusGroup[
    appointmentStatusConstants.StatusGroupCode.CANCELLED
  ]!.map((status) => status.code);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...draftGroupStatuses],
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* createDraftAppointmentSaga(action: CreateDraftAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.createDraftAppointment>> =
      yield call(appointmentApi.createDraftAppointment, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateDraftAppointmentSaga(action: UpdateDraftAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.updateDraftAppointment>> =
      yield call(appointmentApi.updateDraftAppointment, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* savePublishedAppointmentSaga(
  action: SavePublishedAppointmentSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.savePublishedAppointment>> =
      yield call(appointmentApi.savePublishedAppointment, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* cancelAppointmentSaga(action: CancelAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.cancelAppointment>> =
      yield call(appointmentApi.cancelAppointment, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteAppointmentSaga(action: CancelAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.deleteAppointment>> =
      yield call(appointmentApi.deleteAppointment, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* assignManualAppointmentSaga(
  action: AssignManualAppointmentSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.assignManualAppointment>> =
      yield call(appointmentApi.assignManualAppointment, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* assignRescheduledBeautyCenterAppointmentSaga(
  action: AssignRescheduledBeautyCenterAppointmentSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof appointmentApi.assignRescheduledBeautyCenterAppointment>
    > = yield call(appointmentApi.assignRescheduledBeautyCenterAppointment, {
      params,
      cancelToken,
    });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* assignRescheduledCustomerAppointmentSaga(
  action: AssignRescheduledCustomerAppointmentSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof appointmentApi.assignRescheduledCustomerAppointment>
    > = yield call(appointmentApi.assignRescheduledCustomerAppointment, {
      params,
      cancelToken,
    });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateAppointmentSaga(action: UpdateAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.updateAppointment>> =
      yield call(appointmentApi.updateAppointment, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* appointmentSaga() {
  yield all([
    takeEvery(
      AppointmentActionTypes.FETCH_APPOINTMENT_SAGA,
      fetchAppointmentSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_APPOINTMENTS_SAGA,
      fetchAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_DRAFT_APPOINTMENTS_SAGA,
      fetchDraftAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_PROCESSING_ASSIGN_APPOINTMENTS_SAGA,
      fetchProcessingAssignAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_RESCHEDULED_APPOINTMENTS_SAGA,
      fetchRescheduledAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_ACCEPTED_APPOINTMENTS_SAGA,
      fetchAcceptedAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_CHECKED_IN_APPOINTMENTS_SAGA,
      fetchCheckedInAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_ABSENCE_APPOINTMENTS_SAGA,
      fetchAbsenceAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_IN_COMPLETED_APPOINTMENTS_SAGA,
      fetchInCompletedAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_CANCELLED_APPOINTMENTS_SAGA,
      fetchCancelledAppointmentsSaga
    ),
    takeLatest(
      AppointmentActionTypes.CREATE_DRAFT_APPOINTMENT_SAGA,
      createDraftAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.UPDATE_DRAFT_APPOINTMENT_SAGA,
      updateDraftAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.SAVE_PUBLISHED_APPOINTMENT_SAGA,
      savePublishedAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.CANCEL_APPOINTMENT_SAGA,
      cancelAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.DELETE_APPOINTMENT_SAGA,
      deleteAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.ASSIGN_MANUAL_APPOINTMENT_SAGA,
      assignManualAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.ASSIGN_RESCHEDULED_BEAUTY_CENTER_APPOINTMENT_SAGA,
      assignRescheduledBeautyCenterAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.ASSIGN_RESCHEDULED_CUSTOMER_APPOINTMENT_SAGA,
      assignRescheduledCustomerAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.UPDATE_APPOINTMENT_SAGA,
      updateAppointmentSaga
    ),
  ]);
}

export default appointmentSaga;
