import { districtHelpers } from "@/utils/helpers";
import { createSelector } from "@reduxjs/toolkit";

import type { AppState } from "@/store";

export const selectOptionDistricts = (state: AppState) =>
  state.district.optionDistricts;

export const selectOptionDistrictTrees = (state: AppState) =>
  state.district.optionDistricts;

export const selectNestedOptionDistricts = createSelector(
  selectOptionDistricts,
  (optionDistricts) => districtHelpers.mapListToNestedList(optionDistricts)
);

export const selectIdToOptionDistrictMap = createSelector(
  selectOptionDistricts,
  (optionDistricts) => districtHelpers.mapListToIdToItemMap(optionDistricts)
);

export const selectIdToOptionDistrictTreeMap = createSelector(
  selectOptionDistricts,
  (optionDistricts) => districtHelpers.mapListToIdToTreeItemMap(optionDistricts)
);

export const selectBreadcrumbOptionDistricts = createSelector(
  selectIdToOptionDistrictMap,
  (_: AppState, districtId: number | null) => districtId,
  (idToDistrictMap, districtId) => {
    const breadcrumbDistricts = [] as (typeof idToDistrictMap)[number][];
    const addBreadcrumbDistrict = (districtId: number) => {
      const district = idToDistrictMap[districtId];
      if (!!district) {
        breadcrumbDistricts.unshift(district);
        district.parent_id && addBreadcrumbDistrict(district.parent_id);
      }
    };
    districtId && addBreadcrumbDistrict(districtId);
    return breadcrumbDistricts;
  }
);
