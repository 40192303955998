import { AppointmentReportActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";

import type { ReportState, AppointmentReportAction } from "./types";

export const initialState: ReportState = {
  appointmentCountReports: null,
  appointmentCountReportsError: "",
  appointmentCountReportsLoading: false,

  beautyCenterAppointmentChargeTotalReports: [],
  beautyCenterAppointmentChargeTotalReportsError: "",
  beautyCenterAppointmentChargeTotalReportsLoading: false,

  beautyCenterAppointmentCountReports: [],
  beautyCenterAppointmentCountReportsError: "",
  beautyCenterAppointmentCountReportsLoading: false,

  appointmentChargeTotalReports: null,
  appointmentChargeTotalReportsError: "",
  appointmentChargeTotalReportsLoading: false,
};

const reducer = (
  state = initialState,
  action: AppointmentReportAction
): ReportState => {
  switch (action.type) {
    case AppointmentReportActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case AppointmentReportActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every((stateDataItem) => item !== stateDataItem);
        });
        newData = [...stateData, ...filteredData] as any;
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case AppointmentReportActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case AppointmentReportActionTypes.RESET_ALL_REPORTS: {
      return {
        ...state,
        appointmentCountReports: null,
        appointmentCountReportsError: "",
        appointmentCountReportsLoading: false,

        beautyCenterAppointmentChargeTotalReports: [],
        beautyCenterAppointmentChargeTotalReportsError: "",
        beautyCenterAppointmentChargeTotalReportsLoading: false,

        beautyCenterAppointmentCountReports: [],
        beautyCenterAppointmentCountReportsError: "",
        beautyCenterAppointmentCountReportsLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
