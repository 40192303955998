export const PUBLISHED_TYPE = "published";
export const ASSIGNED_BEAUTY_CENTER_BY_SYSTEM_TYPE =
  "assigned_to_beauty_center_by_system";
export const ASSIGNED_TIMEOUT_BY_SYSTEM_TYPE = "assigned_timeout_by_system";
export const ASSIGNED_NOT_FOUND_BY_SYSTEM_TYPE = "assigned_not_found_by_system";
export const DECLINED_TYPE = "declined";
export const ACCEPTED_TYPE = "accepted";
export const CHECKED_IN_BY_BEAUTY_CENTER_TYPE = "checked_in_by_beauty_center";
export const CHECKED_IN_BY_SYSTEM_TYPE = "checked_in_by_system";
export const ABSENCE_TYPE = "absence";
export const ASSIGNED_BEAUTY_CENTER_BY_ADMIN_TYPE =
  "assigned_to_beauty_center_by_admin";
export const CONFIRMED_BY_CUSTOMER_TYPE = "confirmed_by_customer";
export const RESCHEDULED_BY_CUSTOMER_TYPE = "rescheduled_by_customer";
export const RESCHEDULED_BY_BEAUTY_CENTER_TYPE = "rescheduled_by_beauty_center";
export const CANCELED_BY_ADMIN_TYPE = "canceled_by_admin";
export const CANCELED_BY_CUSTOMER_TYPE = "canceled_by_customer";
export const MAILED_TO_ADMIN_TYPE = "mailed_to_admin";
export const SENT_SMS_TO_CUSTOMER_TYPE = "sent_sms_to_customer";
export const UPDATE_APPOINTMENT_TYPE = "update_appointment";

export const appointmentLogTypes = [
  {
    label: "publishedTheAppointment",
    value: PUBLISHED_TYPE,
  },
  {
    label: "assignedTheBeautyCenterByTheSystem",
    value: ASSIGNED_BEAUTY_CENTER_BY_SYSTEM_TYPE,
  },
  {
    label: "timeoutAssignedByTheSystem",
    value: ASSIGNED_TIMEOUT_BY_SYSTEM_TYPE,
  },
  {
    label: "assignedNotFoundByTheSystem",
    value: ASSIGNED_NOT_FOUND_BY_SYSTEM_TYPE,
  },
  {
    label: "declinedTheAppointment",
    value: DECLINED_TYPE,
  },
  {
    label: "acceptedTheAppointment",
    value: ACCEPTED_TYPE,
  },
  {
    label: "beautyCenterHasCheckedInForTheAppointment",
    value: CHECKED_IN_BY_BEAUTY_CENTER_TYPE,
  },
  {
    label: "theSystemHasAutomaticallyCheckedInForTheAppointment",
    value: CHECKED_IN_BY_SYSTEM_TYPE,
  },
  {
    label: "absentedTheAppointment",
    value: ABSENCE_TYPE,
  },
  {
    label: "assignedTheBeautyCenterByAdmin",
    value: ASSIGNED_BEAUTY_CENTER_BY_ADMIN_TYPE,
  },
  {
    label: "confirmedByTheCustomer",
    value: CONFIRMED_BY_CUSTOMER_TYPE,
  },
  {
    label: "rescheduledByTheCustomer",
    value: RESCHEDULED_BY_CUSTOMER_TYPE,
  },
  {
    label: "rescheduledByTheBeautyCenter",
    value: RESCHEDULED_BY_BEAUTY_CENTER_TYPE,
  },
  {
    label: "cancelledByAdmin",
    value: CANCELED_BY_ADMIN_TYPE,
  },
  {
    label: "cancelledByTheCustomer",
    value: CANCELED_BY_CUSTOMER_TYPE,
  },
  {
    label: "sentMailToAdmin",
    value: MAILED_TO_ADMIN_TYPE,
  },
  {
    label: "sentTheNotificationSmsToTheCustomer",
    value: SENT_SMS_TO_CUSTOMER_TYPE,
  },
];
