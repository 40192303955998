export const AUTO_ORDER_TYPE = "auto";
export const MANUAL_ORDER_TYPE = "manual";

export const orderTypes = [
  {
    label: "auto",
    value: AUTO_ORDER_TYPE,
    color: "success.main",
  },
  {
    label: "manual",
    value: MANUAL_ORDER_TYPE,
    color: "info.main",
  },
];

export const typeToOrderTypeMap = [...orderTypes].reduce(
  (labelMap, orderType) => {
    Object.assign(labelMap, {
      [orderType.value]: orderType,
    });
    return labelMap;
  },
  {} as Record<string, (typeof orderTypes)[number]>
);

export const CANCELLED_ACTION = "cancel";
export const UPDATE_ACTION = "update";
// Bỏ
export const RESCHEDULE_BY_CENTER_ACTION = "reschedule_from_center_request";
export const RESCHEDULE_BY_CUSTOMER_ACTION = "reschedule_from_customer_request";
//
export const MANUAL_ASSIGN_ACTION = "assign_manual";
export const DUPLICATE_APPOINTMENT_ACTION = "copy";
