import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { districtApi } from "@/utils/apis";

import { DistrictActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchScope,
  FetchOptionDistrictsSagaAction,
  FetchDistrict1sSagaAction,
  FetchDistrict2sSagaAction,
  FetchDistrict3sSagaAction,
  DeleteDistrictSagaAction,
  UpdateDistrictSagaAction,
  CreateDistrictSagaAction,
} from "./types";

function* fetchOptionDistrictsSaga(action: FetchOptionDistrictsSagaAction) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "optionDistricts" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof districtApi.fetchOptionDistricts>> =
      yield call(districtApi.fetchOptionDistricts, {
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchDistrict1sSaga(action: FetchDistrict1sSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "district1s" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof districtApi.fetchDistricts>> = yield call(
      districtApi.fetchDistricts,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchDistrict2sSaga(action: FetchDistrict2sSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "district2s" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof districtApi.fetchDistricts>> = yield call(
      districtApi.fetchDistricts,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchDistrict3sSaga(action: FetchDistrict3sSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "district3s" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof districtApi.fetchDistricts>> = yield call(
      districtApi.fetchDistricts,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* deleteDistrictSaga(action: DeleteDistrictSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof districtApi.deleteDistrict>> = yield call(
      districtApi.deleteDistrict,
      {
        params,
        cancelToken,
      }
    );

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);

    resolve({ message });
  }
}

function* updateDistrictSaga(action: UpdateDistrictSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof districtApi.updateistricts>> = yield call(
      districtApi.updateistricts,
      {
        params,
        cancelToken,
      }
    );

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);

    resolve({ message });
  }
}

function* createDistrictSaga(action: CreateDistrictSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof districtApi.createDistrict>> = yield call(
      districtApi.createDistrict,
      {
        params,
        cancelToken,
      }
    );

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);

    resolve({ message });
  }
}

function* districtSaga() {
  yield all([
    takeEvery(
      DistrictActionTypes.FETCH_OPTION_DISTRICTS_SAGA,
      fetchOptionDistrictsSaga
    ),
    takeEvery(DistrictActionTypes.FETCH_DISTRICT1S_SAGA, fetchDistrict1sSaga),
    takeEvery(DistrictActionTypes.FETCH_DISTRICT2S_SAGA, fetchDistrict2sSaga),
    takeEvery(DistrictActionTypes.FETCH_DISTRICT3S_SAGA, fetchDistrict3sSaga),
    takeEvery(DistrictActionTypes.DELETE_DISTRICT_SAGA, deleteDistrictSaga),
    takeEvery(DistrictActionTypes.UPDATE_DISTRICT_SAGA, updateDistrictSaga),
    takeEvery(DistrictActionTypes.CREATE_DISTRICT_SAGA, createDistrictSaga),
  ]);
}

export default districtSaga;
