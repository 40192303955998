import { authAxios } from "@/libs/axios";

import type {
  FetchOptionDistrictsPayload,
  FetchOptionDistrictsResponseData,
  FetchOptionProductServiceDistrictsPayload,
  FetchOptionProductServiceDistrictsResponseData,
  FetchDistrictsPayload,
  FetchDistrictsResponseData,
  CreateDistrictPayload,
  UpdateDistrictPayload,
  DeleteDistrictPayload,
  DownloadDistrictsPayload,
  FetchDistrictPayload,
  FetchDistrictResponseData,
} from "./district.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const districtApi = {
  fetchOptionDistricts: (payload?: FetchOptionDistrictsPayload) => {
    return authAxios.get<AxiosResponseData<FetchOptionDistrictsResponseData>>(
      "districts/select_list",
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionProductServiceDistricts: (
    payload: FetchOptionProductServiceDistrictsPayload
  ) => {
    return authAxios.get<
      AxiosResponseData<FetchOptionProductServiceDistrictsResponseData>
    >("districts/select_list_by_product_service", {
      params: payload.params,
      cancelToken: payload?.cancelToken,
    });
  },
  fetchDistricts: (payload?: FetchDistrictsPayload) => {
    return authAxios.get<AxiosResponseData<FetchDistrictsResponseData>>(
      "districts",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchDistrict: (payload: FetchDistrictPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchDistrictResponseData>>(
      `districts/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  createDistrict: (payload: CreateDistrictPayload) => {
    return authAxios.post<AxiosResponseData>("districts", payload.params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateistricts: (payload: UpdateDistrictPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(`districts/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  deleteDistrict: (payload: DeleteDistrictPayload) => {
    const { id } = payload.params;
    return authAxios.delete<AxiosResponseData>(`districts/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
  downloadDistricts: (payload?: DownloadDistrictsPayload) => {
    return authAxios.get<Blob>("districts/export", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
      responseType: "blob",
    });
  },
};

export default districtApi;
