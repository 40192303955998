export const ADMIN_USER_VIEW_NAME = "view-admin";
export const ADMIN_USER_CREATION_NAME = "create-admin";
export const ADMIN_USER_UPDATE_NAME = "update-admin";
export const ADMIN_USER_DELETION_NAME = "delete-admin";

export const ROLE_VIEW_NAME = "view-role";
export const ROLE_CREATION_NAME = "create-role";
export const ROLE_UPDATE_NAME = "update-role";
export const ROLE_DELETION_NAME = "delete-role";

export const BEAUTY_CENTER_VIEW_NAME = "view-beauty-center";
export const BEAUTY_CENTER_CREATION_NAME = "create-beauty-center";
export const BEAUTY_CENTER_UPDATE_NAME = "update-beauty-center";
export const BEAUTY_CENTER_DELETION_NAME = "delete-beauty-center";

export const STAFF_VIEW_NAME = "view-staff";
export const STAFF_CREATION_NAME = "create-staff";
export const STAFF_UPDATE_NAME = "update-staff";
export const STAFF_DELETION_NAME = "delete-staff";

export const CATEGORY_VIEW_NAME = "view-category";
export const CATEGORY_CREATION_NAME = "create-category";
export const CATEGORY_UPDATE_NAME = "update-category";
export const CATEGORY_DELETION_NAME = "delete-category";

export const PRODUCT_SERVICE_VIEW_NAME = "view-product-service";
export const PRODUCT_SERVICE_CREATION_NAME = "create-product-service";
export const PRODUCT_SERVICE_UPDATE_NAME = "update-product-service";
export const PRODUCT_SERVICE_DELETION_NAME = "delete-product-service";

export const GRADE_LEVEL_VIEW_NAME = "view-grade-level";
export const GRADE_LEVEL_CREATION_NAME = "create-grade-level";
export const GRADE_LEVEL_UPDATE_NAME = "update-grade-level";
export const GRADE_LEVEL_DELETION_NAME = "delete-grade-level";

export const DISTRICT_VIEW_NAME = "view-district";
export const DISTRICT_CREATION_NAME = "create-district";
export const DISTRICT_UPDATE_NAME = "update-district";
export const DISTRICT_DELETION_NAME = "delete-district";

export const CUSTOMER_VIEW_NAME = "view-customer";
export const CUSTOMER_CREATION_NAME = "create-customer";
export const CUSTOMER_UPDATE_NAME = "update-customer";
export const CUSTOMER_DELETION_NAME = "delete-customer";

export const PLATFORM_CODE_VIEW_NAME = "view-platform-code";
export const PLATFORM_CODE_CREATION_NAME = "create-platform-code";
export const PLATFORM_CODE_UPDATE_NAME = "update-platform-code";
export const PLATFORM_CODE_DELETION_NAME = "delete-platform-code";

export const APPOINTMENT_VIEW_NAME = "view-appointment";
export const APPOINTMENT_UPDATE_NAME = "update-appointment";

export const ADMIN_ACTION_LOG_VIEW_NAME = "view-admin-action-log";

export const EMAIL_VIEW_NAME = "view-setting-email";
export const EMAIL_CREATION_NAME = "create-setting-email";
export const EMAIL_DELETION_NAME = "delete-setting-email";

export const SMS_LOG_VIEW_NAME = "view-sms-history";
export const SMS_LOG_UPDATE_NAME = "update-sms-history";

export const REPORT_VIEW_NAME = "view-report";
